import bus from '@/eventBus'

export default function globalNotificationCall(text) {
  bus.$emit('notification', text)
}
class NotificationService {
  constructor(eventEmitter) {
    this.eventEmitter = eventEmitter
  }

  emit(event, message) {
    this.eventEmitter.$emit('notification', { event, message })
  }

  info(message) {
    this.emit('info', message)
  }

  error(message) {
    this.emit('error', message)
  }

  success(message) {
    this.emit('success', message)
  }
}

export const notificationService = new NotificationService(bus)
