<template>
  <v-snackbar
    v-model="isShow"
    :color="type"
    top
    right
    timeout="2000"
  >
    <v-card-text
      class="d-flex align-center pa-0 text-h6"
    >
      <v-icon
        v-if="type=='success'"
        color="#fff"
        class="mr-2"
      >
        {{ icons.mdiCheckboxMarkedCircleOutline }}
      </v-icon>
      <v-icon
        v-if="type=='error'"
        color="#fff"
        class="mr-2"
      >
        {{ icons.mdiCloseCircleOutline }}
      </v-icon>
      {{ title }}
    </v-card-text>
    <p
      v-if="text"
      class="mt-2"
    >
      {{ text }}
    </p>
  </v-snackbar>
</template>

<script>

import {
  mdiCheckboxMarkedCircleOutline,
  mdiCloseCircleOutline,
} from '@mdi/js'
import bus from '@/eventBus'

export default {
  data: () => ({
    isShow: false,
    type: null,
    title: null,
    text: null,
    icons: {
      mdiCheckboxMarkedCircleOutline,
      mdiCloseCircleOutline,
    },
  }),
  created() {
    bus.$on('notification', ({ event, message }) => {
      this.type = event
      this.isShow = false

      this.$nextTick(() => {
        this.isShow = true
        this.clear()

        switch (event) {
          case 'success':
            if (typeof message === 'string') {
              this.title = message
            } else if (typeof message === 'object') {
              this.title = message.title
              this.text = message.text
            }
            break
          case 'error':
            this.title = message.title
            this.text = message.text
            if (!this.title) {
              this.title = 'Unknown error occurred'
            }
            break
          case 'info':
            if (typeof message === 'string') {
              this.title = message
            } else if (typeof message === 'object') {
              this.title = message.title
              this.text = message.text
            }
            break
          default: break
        }
      })
    })
  },
  methods: {
    clear() {
      this.title = null
      this.text = null
    },
  },
}
</script>

<style lang="scss" scoped>
.v-snack{
z-index: 999 !important;
position: fixed !important;
}
</style>
