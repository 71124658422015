import {
  mdiAccount, mdiThermostat,
  mdiApps, mdiMessage,
  mdiAccountGroup, mdiRoutes,
  mdiTelegram, mdiCalendarCheck,
  mdiDiscord, mdiAccountBox,
  mdiChartTree, mdiMagnify,
  mdiBookOutline,
  mdiTwitter, mdiCalendar, mdiBadgeAccount, mdiAccountMultiplePlus,
  mdiTooltipAccount,
} from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiApps,
    to: 'home',
  },
  {
    title: 'Documentation',
    icon: mdiBookOutline,
    href: 'https://docs.cometa.tools/cometa-tools-guide/discord-module/get-started',
  },
  {
    title: 'Proxy',
    icon: mdiRoutes,
    to: 'proxy',
  },
  {
    title: 'Telegram',
    icon: mdiTelegram,
    children: [
      {
        title: 'Tasks',
        icon: mdiCalendarCheck,
        to: 'telegramTasks',
      },
      {
        title: 'Accounts',
        icon: mdiAccount,
        to: 'telegramAccounts',
      },
      {
        title: 'Action',
        to: 'telegramAction',
        icon: mdiThermostat,
      },
      {
        title: 'Messages',
        icon: mdiMessage,
        to: 'telegramMessages',
      },
      {
        title: 'Groups',
        icon: mdiAccountGroup,
        to: 'telegramGroups',
      },
    ],
  },
  {
    title: 'Discord',
    icon: mdiDiscord,
    children: [
      {
        title: 'Account',
        to: 'discordAccount',
        icon: mdiAccount,
      },
      {
        title: 'Action',
        to: 'discordAction',
        icon: mdiThermostat,
      },
      {
        title: 'Project',
        to: 'discordProject',
        match: '/discord/project ',
        icon: mdiChartTree,
      },
      {
        title: 'Profile Data',
        icon: mdiAccountBox,
        children: [
          { title: 'Nickname', to: 'discordProfileDataNickname' },
          { title: 'Password', to: 'discordProfileDataPassword' },
          { title: 'Image', to: 'discordProfileDataAvatar' },
          { title: 'Bio', to: 'discordProfileDataBio' },
          { title: 'Profile color', to: 'discordProfileDataProfileColor' },
        ],
      },
      {
        title: 'Find User',
        icon: mdiMagnify,
        children: [{
          title: 'Discord Id',
          href: 'https://discord.id/',
        }, {
          title: 'Lookup Guru',
          href: 'https://lookup.guru/',
        }],
      },
    ],
  }, {
    title: 'Twitter monitor',
    icon: mdiTwitter,
    children: [
      {
        title: 'Trackable users',
        to: 'twitterTrackableUsers',
        icon: mdiBadgeAccount,
      },
      {
        title: 'Sub & unsub tracker',
        to: 'twitterSubscriptions',
        icon: mdiAccountMultiplePlus,
      },
      {
        title: 'Alpha Projects',
        to: 'twitterPopularSubscribers',
        icon: mdiTooltipAccount,
      },
    ],
  },
  {
    title: 'Calendar',
    icon: mdiCalendar,
    to: 'calendar',
  },
]
