import Vue from 'vue'
import VueRouter from 'vue-router'
import { isAuthorized, updateTitle } from './middlewares'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
      title: 'Home',
    },
  },
  {
    path: '/proxy',
    name: 'proxy',
    component: () => import('@/views/proxy/Proxy.vue'),
    meta: {
      layout: 'content',
      title: 'Proxy',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/telegram/accounts',
    name: 'telegramAccounts',
    component: () => import('@/views/telegram/Accounts.vue'),
    meta: {
      title: 'Accounts',
      layout: 'content',
    },
  },
  {
    path: '/telegram/action',
    name: 'telegramAction',
    component: () => import('@/views/telegram/Action.vue'),
    meta: {
      title: 'Actions',
      layout: 'content',
    },
  },
  {
    path: '/telegram/action/:id',
    name: 'telegramActionDetails',
    component: () => import('@/views/telegram/ActionDetails.vue'),
    meta: {
      title: 'Action Detail',
      layout: 'content',
    },
  },
  {
    path: '/telegram/messages',
    name: 'telegramMessages',
    component: () => import('@/views/telegram/Messages.vue'),
    meta: {
      title: 'Messages',
      layout: 'content',
    },
  },
  {
    path: '/telegram/groups',
    name: 'telegramGroups',
    component: () => import('@/views/telegram/Groups.vue'),
    meta: {
      title: 'Groups',
      layout: 'content',
    },
  },
  {
    path: '/telegram/tasks',
    name: 'telegramTasks',
    component: () => import('@/views/telegram/Tasks.vue'),
    meta: {
      title: 'Tasks',
      layout: 'content',
    },
  },
  {
    path: '/discord/account',
    name: 'discordAccount',
    component: () => import('@/views/discord/Account.vue'),
    meta: {
      title: 'Accounts',
      layout: 'content',
    },
  },
  {
    path: '/discord/project/:id',
    name: 'discordProjectDetails',
    component: () => import('@/views/discord/ProjectDetails.vue'),
    meta: {
      title: 'Project Details',
      layout: 'content',
      isBack: true,
    },
  },
  {
    path: '/discord/project',
    name: 'discordProject',
    component: () => import('@/views/discord/Project.vue'),
    meta: {
      title: 'Projects',
      layout: 'content',
    },
  },
  {
    path: '/discord/action/:id',
    name: 'discordActionDetails',
    component: () => import('@/views/discord/ActionDetails.vue'),
    meta: {
      title: 'Action Details',
      layout: 'content',
      isBack: true,
    },
  },
  {
    path: '/discord/action',
    name: 'discordAction',
    component: () => import('@/views/discord/Action.vue'),
    meta: {
      title: 'Actions',
      layout: 'content',
    },
  },
  {
    path: '/discord/profile-data/nickname',
    name: 'discordProfileDataNickname',
    component: () => import('@/views/discord/profile-data/Nickname.vue'),
    meta: {
      title: 'Nicknames',
      layout: 'content',
    },
  },
  {
    path: '/discord/profile-data/password',
    name: 'discordProfileDataPassword',
    component: () => import('@/views/discord/profile-data/Password.vue'),
    meta: {
      title: 'Passwords',
      layout: 'content',
    },
  },
  {
    path: '/discord/profile-data/avatar',
    name: 'discordProfileDataAvatar',
    component: () => import('@/views/discord/profile-data/Avatar.vue'),
    meta: {
      title: 'Images',
      layout: 'content',
    },
  },
  {
    path: '/discord/profile-data/bio',
    name: 'discordProfileDataBio',
    component: () => import('@/views/discord/profile-data/Bio.vue'),
    meta: {
      title: 'Bios',
      layout: 'content',
    },
  },
  {
    path: '/discord/profile-data/profile-color',
    name: 'discordProfileDataProfileColor',
    component: () => import('@/views/discord/profile-data/ProfileColor.vue'),
    meta: {
      title: 'Profile Colors',
      layout: 'content',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings.vue'),
    meta: {
      title: 'Settings',
      layout: 'content',
    },
  },
  {
    path: '/referral',
    name: 'referral',
    component: () => import('@/components/settings/ReferralSection.vue'),
    meta: {
      title: 'Referral system',
      layout: 'content',
    },
  },
  {
    path: '/twitter/trackable-users',
    name: 'twitterTrackableUsers',
    component: () => import('@/views/twitter/TrackableUsers.vue'),
    meta: {
      title: 'Trackable users',
      layout: 'content',
    },
  },
  {
    path: '/twitter/subscriptions',
    name: 'twitterSubscriptions',
    component: () => import('@/views/twitter/Subscriptions.vue'),
    meta: {
      title: 'Subscriptions & unsubscriptions',
      layout: 'content',
      isBack: true,
    },
  },
  {
    path: '/twitter/twitter-user',
    name: 'twitterPopularSubscribers',
    component: () => import('@/views/twitter/TwitterUser.vue'),
    meta: {
      title: 'Twitter user',
      layout: 'content',
      isBack: true,
    },
  },
  {
    path: '/twitter/current-subscription',
    name: 'twitterCurrentSubscription',
    component: () => import('@/views/twitter/CurrentSubscriptions.vue'),
    meta: {
      title: 'Current subscription',
      layout: 'content',
      isBack: true,
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/Calendar.vue'),
    meta: {
      title: 'Calendar',
      layout: 'content',
    },
  },
  {
    path: '*',
    name: 'error404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'content',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(isAuthorized)
router.beforeEach(updateTitle)

export default router
