import SettingsApi from '@core/api/settings'

export const USER_MODULE_NAME = 'app-user'

export default {
  namespaced: true,
  state: {
    user: {},
  },
  getters: {},
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    setDelay({ commit }, params) {
      return SettingsApi.Delay.setDelay(params).then(response => {
        commit('SET_USER', response.data)

        return response
      })
    },
    setNewCaptcha({ commit }, params) {
      return SettingsApi.Captcha.updateCaptcha(params).then(async response => {
        const user = await SettingsApi.User.getData()
        commit('SET_USER', user.data)

        return response
      })
    },
  },
}
