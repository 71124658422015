// axios
import axios from 'axios'
import Vue from 'vue'
import router from '../router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://api.disbar-app.click',

  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error)
  ,
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    const errObj = {}
    if (typeof error === 'string') {
      errObj.title = 'Unexpected error'
    } else if (typeof error === 'object') {
      if (String(error.response?.status).includes('50')) {
        errObj.title = 'Server error'
        errObj.text = 'Something went wrong'
      } else if (error.response?.status === 403) {
        errObj.title = 'Unauthorized'
        errObj.text = 'User not authorized'
        router.push('/login')
      } else {
        errObj.title = error.response?.data.Code
        errObj.text = error.response?.data.Message
        if (errObj.title === undefined) {
          errObj.title = 'Unknown error occurred'
        }
      }
    }

    return Promise.reject(errObj)
  },
)

export default axiosIns
