// eslint-disable-next-line import/prefer-default-export
export const isAuthorized = (to, from, next) => {
  if (!localStorage.getItem('accessToken') && to.name !== 'auth-login' && to.name !== 'auth-register') {
    next({ name: 'auth-login' })
  } else if (to.name === 'auth-login' && localStorage.getItem('accessToken')) {
    next('/')
  } next()
}

export const updateTitle = (to, from, next) => {
  const $title = document.head.querySelector('title')
  $title.textContent = to.meta?.title ? `${to.meta?.title} | Cometa` : 'Cometa'
  next()
}
