import axios from '@axios'

export default {
  User: {
    getData: () => axios.get('/user'),
    registerUser: payload => axios.post('/user', payload),
  },
  Captcha: {
    updateCaptcha: payload => axios.post('/captcha/set', payload),
  },
  Delay: {
    setDelay: payload => axios.post('/user/set_delay', payload),
  },
  Referral: {
    getStatistic: () => axios.get('/referral/statistic'),
    getReferralList: params => axios.get('/referral/list', { params }),
  },
}
