// import '@/@fake-db/db'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import axios from '@axios'
import { notificationService } from './@core/utils/globalNotification'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.filter('nillToEmpty', value => {
  if (value === null || value === undefined) {
    return value
  }

  return value
})

Vue.prototype.$message = notificationService
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
